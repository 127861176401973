<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{
        'form-group--error': error,
        'form-group--compact': compact,
        'text-bold': boldLabel,
      }"
    >
      <b-form-input
        :class="{
          'text-bold': boldValue,
          'right-align': rightAlignValue,
        }"
        :id="`${id}-input`"
        :name="`${id}-input`"
        :type="type"
        :value="inputValue"
        @input="onInput"
        @change="updateValue(id, $event)"
        @blur="applyFormatting"
        v-bind="$attrs"
        v-uppercase="uppercase"
        :autocomplete="autocomplete"
        :plaintext="plaintext"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
        :tabindex="tabIndex"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Enter amount',
    },
    autocomplete: { type: String, default: "on" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    textClass: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    type: { type: String, default: "text" },
    uppercase: { type: Boolean, default: false },
    classField: { type: String, default: "" },
    textFieldWidth: { type: String, default: "" },
    boldValue: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    rightAlignValue: { type: Boolean, default: false },
    marginLeftValue: { type: Number, default: 0 }
  },
  data() {
    return {
      inputValue: this.value ? this.value.toString() : ''
    };
  },
  mounted() {
    // Initialize with formatted value if necessary
    if (this.inputValue !== '' && !isNaN(this.inputValue)) {
      this.inputValue = parseFloat(this.inputValue).toFixed(2);
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue ? newValue.toString() : ''
    }
  },
  computed: {
    // formattedValue() {
    //   console.log("in formattedValue. value: ", this.value)
    //   // Format the input value to always show two decimal places
    //   return this.value !== null && this.value !== undefined ? parseFloat(this.value).toFixed(2) : '';
    // },
    tabIndex() {
      return this.readonly || this.plaintext ? "-1" : "0";
    }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    onInput(event) {
      const rawValue = event?.target?.value;
      // Validate that the input is a valid number
      if (this.isValidNumber(rawValue)) {
        this.inputValue = rawValue;
        this.$emit('input', rawValue); 
      }
     },
    applyFormatting() {
      // Apply the formatting when the input loses focus
      if (this.inputValue !== '' && !isNaN(this.inputValue)) {
        const formatted = parseFloat(this.inputValue).toFixed(2);
        this.inputValue = formatted;
        this.$emit('input', formatted); // Emit the formatted value back to the parent
      }
    },
    isValidNumber(value) {
        // Allow empty input for typing, then validate
        if (value === '') return true;
        // Check if the value is a valid number (regex to allow only numbers and a single decimal point)
        return /^\d*\.?\d*$/.test(value);
      }
  },
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
