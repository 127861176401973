<template>
    <div >
        <b-form-row>
            <h4 class="section-heading">Purchased Item Information</h4>
        </b-form-row>
        <b-form-row>
            <b-col cols="6">
                <div>
                <!-- Top-left controls -->
                <BaseFormInput
                v-for="item in topLeft"
                :key="item?.id"
                :id="item?.id"
                :label="item.label"
                :labelCols=5
                plaintext
                labelClass="text-muted"
                size="sm"
                readonly
                compact
                :value="item.value"
                marginLeftValue="-12"
                />
                </div>
            </b-col>
            <b-col>
                <div>
                    <!-- Top-right controls -->
                    <BaseFormInput
                    v-for="item in topRight"
                    :key="item?.id"
                    :id="item?.id"
                    :label="item.label"
                    labelAlign="right"
                    :labelCols=5
                    plaintext
                    labelClass="text-muted"
                    size="sm"
                    readonly
                    compact
                    :value="item.value"
                    />
                </div>
                <div class="qty-totals-container">
                    <hr class="m-0 p-0 mr-1"/>
                    <div class="qty-totals d-flex align-items-center justify-content-end">
                        <span class="qty-value">{{ expectedQty }}</span>
                        <span class="qty-label, qty-label-expected">Expected qty</span>
                    </div>
                </div>
                <!-- Received/issue qty -->
                <div>
                    <BaseFormInput
                    v-for="item in totalReceivedIssueQty"
                    :key="item?.id"
                    :id="item?.id"
                    :label="item.label"
                    labelAlign="right"
                    :labelCols=5
                    plaintext
                    labelClass="text-muted"
                    size="sm"
                    readonly
                    compact
                    :value="item.value"
                    />
                </div>
                <div class="qty-totals-container">
                    <hr class="m-0 p-0 mr-1"/>
                    <div class="qty-totals">
                        <span class="qty-value">{{ pendingQty }}</span>
                        <span class="qty-label, qty-label-pending">Pending qty</span>
                    </div>
                </div>
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
export default {
    props: {
        issue: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localIssue: this.issue
        };
    },
    watch: {
        issue(newIssue) {
            this.localIssue = newIssue;
        }
    },
    computed: {
        topLeft() {
            return [
                { id: 'id', label: 'Purchased item id:', value: this.localIssue?.purchasedItemRecordId },
                { id: 'orderNumber', label: 'Order number:', value: this.localIssue?.orderNumber},
                { id: 'trackingNubmer', label: 'Source tracking #:', value: this.localIssue?.trackingNumber },
                { id: 'asin', label: 'Asin:', value: this.localIssue?.asin },
                { id: 'sku', label: 'SKU:', value: this.localIssue?.sku },
                { id: 'fnsku', label: 'FnSku:', value: this.localIssue?.fnsku },
                { id: 'upc', label: 'UPC:', value: this.localIssue?.upc },
            ]
        },
        topRight() {
            return [
                { id: 'purchasedQty', label: 'Purchased qty:', value: this.localIssue?.purchasedQty || 0},
                { id: 'extraQty', label: 'Extra qty:', value: this.localIssue?.extraQty || 0},
                { id: 'replacementQty', label: 'Replacement qty:', value: this.localIssue?.replacementQty || 0},
                { id: 'canceledQty', label: 'Canceled qty:', value: this.localIssue?.canceledQty || 0},
            ]
        },
        totalReceivedIssueQty() {
            return [
                { id: 'pastReceivedQty', label: 'Total received qty:', value: this.localIssue?.pastReceivedQty || 0},
                { id: 'totalIssueQty', label: 'Total issue qty:', value: this.localIssue?.totalIssueQty || 0},
            ]
        },
        expectedQty() {
            return Number(this.localIssue.purchasedQty || 0) + Number(this.localIssue.extraQty || 0) + Number(this.localIssue.replacementQty || 0) - Number(this.localIssue.canceledQty || 0);
        },
        pendingQty() {
            return Number(this.expectedQty || 0) - Number(this.localIssue.pastReceivedQty || 0) - Number(this.localIssue.totalIssueQty || 0);
        },
        noHistory() {
            return this.issue.logs?.length === 0;
        }
    },
    methods: {
        convertNumberToBoolean(value) {
            return value === 1 ? "true": "false"
        },
    },
};
</script>

<style scoped>
.custom-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.custom-modal .modal-content {
  margin: auto; /* Center content horizontally */
}
.section-heading {
    color: #555;
    font-size: .9em;
    font-weight: 600;
    margin-left: 5px;
}

.qty-totals-container {
    position: relative;
} 

.qty-totals {
    position: absolute;
    bottom: 0;
    right: 0;
}

.qty-value {
    font-weight: 600;
}

.qty-label, .qty-label-pending, .qty-label-expected {
    margin-left: 15px;
    font-size: .9rem;
    color: #555;
}

.qty-label-expected {
    margin-right: 35px;
}

.qty-label-pending {
    margin-right: 40px;
    margin-left: 15px;
}

</style>
