<template>
  <div class="d-flex button-container">
    <!-- Show fitler row
    <b-button
      class="b-button"
      title="Add a new record"
      @click.prevent="$emit('action-filter')"
    >
      <i
        class="fas fa-filter"
        :class="[state.showFilterRow ? 'filter-icon-active' : 'filter-icon']"
      ></i>
    </b-button> -->
    <!-- Upload -->
    <b-button
      class="b-button mr-2"
      title="Upload file into this list"
      @click.prevent="$emit('action-upload')"
      v-if="showUpload"
    >
      <i class="fas fa-file-upload file-upload-icon"></i>
    </b-button>
    <!-- Add -->
    <b-button
      class="b-button add-button mr-2"
      title="Add a new record"
      @click.prevent="$emit('action-add')"
      v-if="showAdd"
    >
      <i class="fas fa-plus-circle add-icon"></i>
    </b-button>
    <!-- Refresh -->
    <b-button
      class="b-button refresh-button"
      :title="refreshTooltip"
      @click.prevent="refresh"
      v-if="showRefresh"
    >
      <i class="fas fa-sync-alt refresh-icon"></i>
    </b-button>
    <BaseHeadingOtherAction
      :namespace="namespace"
      class="my-auto"
      v-if="showOtherActions"
      :allowDelete="allowDelete"
    />
  </div>
</template>

<script>
import config from "../../config";

export default {
  props: {
    namespace: { type: String, default: "" },
    showAdd: { type: Boolean, default: true },
    showUpload:  { type: Boolean, default: false },
    showRefresh: { type: Boolean, default: true },
    showOtherActions: { type: Boolean, default: true },
    allowDelete: { type: Boolean, default: true }
  },
  async created() {
    // runs when the element is injected into the browser
    //this.refresh();
    // Auto refresh list
    this.timer = setInterval(
      await this.refresh,
      config.autoListRefreshIntervalMin * 60 * 1000
    );
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    state() {
      return this.$store.state[this.namespace];
    },
    selectedCount() {
      return this.state.selectedItems.length;
    },
    refreshTooltip() {
      return `Refresh list: \r${
        this.$store.getters[`${this.namespace}/lastRefreshed`]
      }`;
    },
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(`${this.namespace}/fetchAll`, this.routeName);
    }
  }
};
</script>

<style lang="css" scoped>
i {
  font-size: 1.6em;
  color: rgb(73, 73, 73);
  /* padding: 0px;
  margin: 0px; */
  transition: 0.2s;
}
i:hover {
  transform: scale(1.1, 1.1);
}

.button-container {
  margin-right: 0px;
  background-color: RGB(248, 248, 248);
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  /* padding: 0; */
}
.btn {
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: 0.2s;

}
.btn:hover {
  transform: scale(1.2, 1.2);
}
.file-upload-icon {
  font-size: 1.5em;
  color: #12911a;
  vertical-align: middle;
  margin-right: 0.2em; 
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.add-button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-top: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.add-icon {
  font-size: 1.5em;
  color: #007bff;
  vertical-align: middle;
  margin-left: -4px;
  margin-top: -12px;
}
.filter-icon {
  font-size: 1.2em;
  color: rgb(73, 73, 73);
  vertical-align: middle;
}
.filter-icon-active {
  font-size: 1.2em;
  color: #007bff;
  vertical-align: middle;
}
.delete-icon {
  font-size: 1.2em;
  color: rgb(73, 73, 73);
  vertical-align: middle;
}
.refresh-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.refresh-icon {
  font-size: 1.1em;
  color: rgb(109, 107, 107);
  margin-top: -2px;
  margin-bottom: 5px;
  margin-left: -3px;
  margin-right: 4px;
}
</style>