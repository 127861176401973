<template>
	<b-overlay
		variant="white"
		:show="isLoading && showLoading && showOverlay && useShowLoadingSpinner"
		:style="{ cursor: isLoading ? 'wait' : 'auto' }"
		:opacity="0.0"
		spinner-variant="info"
		class="mx-auto overlay"
	>
		<!-- Table -->
		<div
			id="main-page"
			ref="mainpage"
			@keydown.insert.exact="showItemDetail(-1)"

		>
			<b-row class="filter-bar">
				<b-col class="d-flex pl-0 pr-0">
					<BaseFilterBar
						ref="baseFilterBar"
						:namespace="namespace"
						@clear="clearSelected"
						:searchBoxWidth="searchBoxWidth"
						:fetchOnStart="fetchOnStart"
					/>
					<!-- Action menu to the right -->
					<BaseTableAction
						class="ml-auto"
						:namespace="namespace"
						@action-add="showNewItemDetail()"
						@action-upload="showFileUpload"
						:showUpload="showUpload"
						:showAdd="showAdd"
						:showRefresh="showRefresh"
						:showOtherActions="showOtherActions"
						:allowDelete="allowDelete"
						:filter="useRouteName"
					/>
				</b-col>
			</b-row>
			<div class="">
				<div
					class="sticky-table-wrapper"
					id="btableDiv"
					ref="btableDiv"
					:style="`border-top: 2px solid rgb(${accentColor}, .6)`"
				>
					<b-table
						id="btable"
						ref="btable"
						:items="items"
						:fields="list.fields ? list.fields : fields"
						primary-key="id"
						class="border-bottom bg-white shadow-sm table-condensed"
						head-variant="light"
						thead-class="text-smaller"
						outlined
						responsive="sm"
						small
						hover
						:selectable="selectable"
						:selectMode="selectMode"
						@sort-changed="onSortChanged"
						@row-selected="onRowSelected"
						@row-clicked="onRowClicked"
						:sticky-header="tableHeight"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortOrder"
						no-border-collapse
						:no-local-sorting="noLocalSorting"
						show-empty
						empty-text="There are no records to show"
					>
						<!-- Row index -->
						<template #cell(index)="data">
							<span class="row-index text-right">{{
								rowNumber(data.index)
							}}</span>
						</template>
						<!-- Select column -->
						<template #cell(selected)="{ rowSelected }">
							<template v-if="rowSelected">
								<span aria-hidden="true">&check;</span>
								<span class="sr-only">Selected</span>
							</template>
							<template v-else>
								<span aria-hidden="true">&nbsp;</span>
								<!-- Causes browser scrollbar but didn't when with RestrictedList -->
								<!-- <span class="sr-only">Not selected</span> -->
							</template>
						</template>

						<!-- Select column header -->
						<template #head(selected)="{ label }">
							<template v-if="label">
								<b-button
									variant="outline secondary"
									@click="selectAllRows"
									class="check-header"
									>&check;</b-button
								>
							</template>
						</template>

						<!-- Action column: See detail link (key field called 'action' in fields array) -->
						<template #cell(action)="row" v-if="items">
							<b-nav class="p-0" :style="`padding: 0`">
								<b-link
									class="p-0"
									:class="{
										'unread-record':
											row.item.unread &&
											row.item.assigneeFamiliarName === user.familiarName,
									}"
									:style="'padding: 0;'"
									@click="showItemDetail(row.item, row.index, $event.target)"
								>
									{{ row.item[keyField] }}
								</b-link>
							</b-nav>
						</template>

						<!-- Amazon link column: amazonLinkField called 'amazonLink' in fields array) -->
						<template #cell(amazonLink)="row" v-if="items">
							<b-nav class="p-0" :style="`padding: 0`">
								<template v-if="getFieldProperty('amazonLink', 'showLink')">
									<b-link
										id="amazon-link"
										class="p-0"
										:style="`padding: 0`"
										target="_blank"
										:href="
											row.item[amazonUrlField]
												? row.item[amazonUrlField]
												: `https://www.amazon.com/dp/${row.item.asin}?th=1&psc=1`
										"
										rel="noopener noreferrer"
									>
										{{ row.item[amazonLinkField] }}
									</b-link>
								</template>
								<!-- Display plain text if showLink is false -->
								<template v-else>
									{{ row.item[amazonLinkField] }}
								</template>
							</b-nav>
							<!-- <b-tooltip target="amazon-link"
                >Click to see Amazon listing</b-tooltip
              > -->
						</template>

						<!-- Raw html data -->
						<template #cell(rawHtml)="row" v-if="items">
							<div v-html="formatHtml(row.item[rawHtmlField])"></div>
						</template>

						<!-- CRUD action field format (insert, update, delete) -->
						<template #cell(crudAction)="row" v-if="items">
							<div :class="crudActionClass(row.item[crudActionField])">
								{{ row.item[crudActionField] }}
							</div>
						</template>

						<!-- Priority: 1-Urgent is marked danger background -->
						<!-- Assumes field named priority -->
						<template #cell(priorityName)="row" v-if="items">
							<span
								:class="{
									'priority-urgent': row.item.priorityName === '1-Urgent',
									'priority-important': row.item.priorityName === '2-Important',
									'unread-record':
										row.item.unread &&
										row.item.assigneeFamiliarName === user.familiarName,
								}"
							>
								{{ row.item.priorityName }}
							</span>
						</template>

						<!-- Image -->
						<template #cell(image)="row" v-if="items">
							<b-img
								v-if="row.item[imageField]"
								:src="row.item[imageField] || defaultImage"
								class="p-1 d-flex"
								:alt="row.item[imageField] ? 'Item Image' : ''"
								height="40"
								fluid
							></b-img>
						</template>
						<!-- Attachments column header -->
						<template #head(hasAttachments)="{ label }">
							<template v-if="label">
								<i
									v-b-tooltip.hover
									title="Record has attachments"
									class="fas fa-paperclip paper-clip"
								>
								</i>
							</template>
						</template>
						<!-- Attachments Cell -->
						<template #cell(hasAttachments)="row" v-if="items">
							<div v-if="row.item['hasAttachments']">
								<i class="fas fa-paperclip paper-clip"></i>
							</div>
						</template>
						<!-- Active Issue Column Header -->
						<template #head(hasActiveIssue)="{ label }">
							<template v-if="label">
								<i
									v-b-tooltip.hover
									title="Record has related issues"
									class="fas fa-flag issue-flag-inactive"
								>
								</i>
							</template>
						</template>
						<!-- Active Issue Cell -->
						<template #cell(hasActiveIssue)="row" v-if="items">
							<div v-if="row.item['hasIssueCount'] && row.item['hasActiveIssue'] ">
									<i v-b-tooltip.hover title="Active issue(s)" class="fas fa-flag issue-flag-active"></i>
							</div>
							<div v-else-if="row.item['hasIssueCount']">
									<i v-b-tooltip.hover title="Closed issue(s)" class="fas fa-flag issue-flag-inactive" ></i>
							</div>
						</template>
						<!-- Normal cell -->
						<template #cell()="data"
							><span
								:class="{
									'unread-record':
										data.item.unread === 1 &&
										data.item.assigneeFamiliarName === user.familiarName,
								}"
								>{{ data.value }}</span
							>
						</template>
						<!-- Normal header - tooltip -->
						<template #head()="data">
							<span v-b-tooltip.hover :title="data.field.tooltip">
								{{ data.label }}
							</span>
						</template>
						<!-- Footer -->
						<template slot="custom-foot" v-if="items && items.length > 0">
							<!-- Total -->
							<b-tr
								:style="'font-weight: 700'"
								:class="{ 'footer-row': list.calcs.length > 0 }"
							>
								<template v-for="(field, index) in fields">
									<b-td
										:key="index"
										v-if="field.showTotal"
										:class="field.class"
									>
										{{
											field.key.toLowerCase().includes("amt")
												? formatCurrency(
														list.calcs[field.key],
														field.decimalDigits === undefined
															? null
															: field.decimalDigits
                    )
												: field.key.toLowerCase().includes("pct")
												? `${(list.calcs[field.key] * 100).toFixed(0)}%`
												: Number(list.calcs[field.key]).toLocaleString()
										}}
									</b-td>
									<b-td :key="index" v-if="!field.showTotal"></b-td>
								</template>
							</b-tr>
						</template>
						<!-- Row detail (for drill-down) -->
						<template #cell(show_details)="row">
							<div class="show-detail-button-container">
								<b-button
									variant="Light"
									size="sm"
									class="b-button show-detail-button"
									@click.prevent="toggleDetails(row.index)"
									v-if="showDrilldown"
								>
									<!-- Turn detail arrow around after detail is displayed -->
									<p class="row-detail-p">
										<b-icon-chevron-down
											:class="[
												'flip-row-detail',
												{ 'flip-row-detail-arrow': row.detailsShowing },
											]"
										/>
									</p>
								</b-button>
							</div>
						</template>
						<template slot="row-details" slot-scope="row">
							<b-card no-body>
								<BaseG3RowDetail
									:namespace="drilldownNamespace"
									:id="row.item?.id"
									:toggle="row.detailShowing"
								/>
							</b-card>
						</template>
					</b-table>
				</div>
				<BasePagination :namespace="namespace" />
			</div>
			<!-- *************************************************************** -->
			<!-- *******************  ITEM MODALS  ***************************** -->
			<!-- <store>ItemModal and <store>ItemModalRef -->
			<!-- IssueItem modal -->
			<b-modal
				id="Issue2ItemModal"
				ref="Issue2ItemModalRef"
				hide-footer
				hide-header
				content-class="shadow"
				no-close-on-esc
				no-close-on-backdrop
				@shown="onModalOpen"
				@hidden="onModalClose"
			>
				<IssueItem
					ref="Issue2ItemRef"
					:item="modalItemData"
					parentNamespace="BaseTableG3"
					@cancelModal="cancelModal"
					@itemSaved="onModalItemSaved"
				/>
			</b-modal>
			<!-- PurchasedItem modal -->
			<b-modal
				id="PurchasedItemItemModal"
				ref="PurchasedItemItemModalRef"
				hide-footer
				hide-header
				content-class="shadow"
				no-close-on-esc
				no-close-on-backdrop
				@shown="onModalOpen"
				@hidden="onModalClose"
				class="draggable-modal"
			>
			<!-- For dragging modal around (unfinished) -->
				<!-- <div class="draggable-header" @mousedown="startDrag">
					<h4>Purchased Item Modal</h4>
				</div> -->
				<PurchasedItem
					ref="PurchasedItemItemRef"
					:item="modalItemData"
					@cancelModal="cancelModal"
					@childModalStateChange="onChildModalStateChange"
					@itemSaved="onModalItemSaved"
				/>
			</b-modal>
		</div>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import defaultImage from "../assets/image-not-available.png";
import IssueItem from '../views/issue/IssueItem.vue'
import PurchasedItem from '../views/purchased-items/PurchasedItem.vue'
import {
  //  handleRouterPushError,
  formatRawHtml,
	formatCrudAction,
	postMsgBoxOk,
	handleModalOnOutsideClick,
	handleModalShowItemDetail
} from "../utils/component-utils";
import draggableMixin from "../views/common/draggableMixin.js";

export default {
  components: {IssueItem, PurchasedItem},
  mixins: [draggableMixin],
  props: {
    namespace: {
      type: String,
      default: ""
    },
    routeName: {
      type: String,
      default: ""
    },
    fields: {
      type: Array,
      default: null
    },
    keyField: { type: String, default: "asin" },
    imageField: { type: String, default: "imageUrl" },
    amazonLinkField: { type: String, default: "asin" },
    amazonUrlField: { type: String, default: "amazonUrl" },
    rawHtmlField: { type: String, default: "changes" },
    crudActionField: { type: String, default: "operation" },
    selectMode: { type: String, default: "multi" },
    selectable: { type: Boolean, default: true },
    searchBoxWidth: { type: String, default: "350" },
    showOverlay: { type: Boolean, default: true },
    formTop: { type: Number, default: 0 },
    showOtherActions: { type: Boolean, default: true },
		showAdd: { type: Boolean, default: true },
		showUpload: { type: Boolean, default: false },
    showRefresh: { type: Boolean, default: true },
    showDrilldown: { type: Boolean, default: false },
    drilldownNamespace: { type: String, default: "" },
    allowInsert: { type: Boolean, default: true },
    selectRowShowsDetail: { type: Boolean, default: false },
    allowDelete: { type: Boolean, default: true },
    fetchOnStart: { type: Boolean, default: true },
    showLoadingSpinner: { type: Boolean, default: true }
  },
  data() {
    return {
      modalItemData: null,
      forceClose: false,
			isIssueItemOpen: false,
      tableSelected: false,
      window: {
        width: 0,
        height: 0
      },
      tableHeight: "",
      fixSortFlag: false,
      showWaitCursor: false,
      noLocalSorting: true,
			useShowLoadingSpinner: this.$props.showLoadingSpinner,
			justSavedModalItem: false,
			selectedRowItemId: 0,
    };
  },
  async created() {
    this.handleResize();
  },
	async mounted() {
    // runs when the element is injected into the browser
    // handlResize makes the table height relative to the viewport height
    //this.handleResize();
    // Save incoming route (determines fetchall filter)
		this.$nextTick(async () => {
			this.handleResize();
			if (this.$route.params.reload === undefined) {
				await this.fetchAll();
			} else if (this.$route.params.reload) {
				await this.fetchAll();
			}
		});
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keydown", this.shortcutListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    // Column resize...
    // document.removeEventListener("mousemove");
    // document.removeEventListener("mouseup");
    window.removeEventListener("keydown", this.shortcutListener);
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    ...mapGetters("Session", [
      "isLoading",
      "showLoading",
      "accentColor",
      "isAuthorized",
			"user",
			"tenantSelectedClient"
    ]),
    useRouteName() {
      return this.$route.name;
    },
    defaultImage() {
      return defaultImage;
    },
    showState() {
      // return this.$store.state[this.namespace];
      return this.$store.getters[`${this.namespace}/showState`];
    },
    list() {
      // return this.$store.state[this.namespace];
      return this.$store.getters[`${this.namespace}/list`];
    },
    items() {
      return this.$store.getters[`${this.namespace}/items`];
    },
    lastRefreshed() {
      return this.$store.getters[`${this.namespace}/lastRefreshed`];
    },
    sortBy: {
      async set(sortBy) {
        // If sorted on action or amazonLink field, replace with keyField in props
        let useSortBy =
          sortBy === "action" || sortBy === "amazonLink"
            ? this.keyField
            : sortBy;
        await this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "sortBy",
          value: useSortBy
        });
        //this.fetchAll();
      },
      get() {
        return this.list.sortBy;
      }
    },
    sortOrder: {
      async set(sortOrder) {
        // sortOder set
        await this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "sortOrder",
          value: sortOrder ? "DESC" : "ASC"
        });
        //this.fetchAll();
      },
      get() {
        const order = this.list.sortOrder;
        return order === "DESC" ? true : false;
      }
    },
    selectedItems() {
      const items = this.list.selectedItems;
      if (items.length === 0) {
        this.$refs.btable.clearSelected();
      }
      return items;
    }
  },
	watch: {
		async tenantSelectedClient() {
			// Tenant selected client has changed: Refresh page
			await this.fetchAll();
      this.$nextTick( async () => {
				await this.$store.dispatch(`${this.namespace}/clearFiltersOnly`);
      });
		},
	},
  methods: {
    getFieldProperty(key, property) {
      const field = this.fields.find(field => field.key === key);
      return field ? field[property] : null;
    },
    crudActionClass(action) {
      return formatCrudAction(action);
    },
    formatHtml(rawHtml) {
      return formatRawHtml(rawHtml);
    },
    formatCurrency(amt, decimalDigits) {
      const fmtr = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
        maximumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2
      });
      return fmtr.format(amt);
    },
    shortcutListener(event) {
      if (event.ctrlKey && event.key === "f") {
        // Ctl-f: Search
        this.$refs.baseFilterBar.focusOnSearch();
        // alert(`ctl-f: ${this.$refs["searchField"].name}`);
        event.preventDefault();
      } 
    },
		async fetchAll() {
			this.$nextTick(async () => {
				if (!this.fetchOnStart) return;
				// If we have all rows, local sorting
				if (
					this.list?.items?.length > 0 &&
					this.list?.items?.length === this.list?.totalRows
				) {
					this.noLocalSorting = false;
					// 2/9/22: Fix issue where not updating current item in list if all items fit on one page
					await this.$store.dispatch(
						`${this.namespace}/fetchAll`,
						this.useRouteName
					);
				} else {
					// Server-side sorting
					this.noLocalSorting = true;
					await this.$store.dispatch(
						`${this.namespace}/fetchAll`,
						this.useRouteName
					);
				}
				// Refresh selected row
				if (this.selectedRowItemId > 0) {
					this.selectedRowIndex = this.items?.findIndex(item => item?.id === this.selectedRowItemId);
					if (this.selectedRowIndex !== -1) {
						this.$refs.btable.selectRow(this.selectedRowIndex);
					}
				}
				// Call handleResize or other layout adjustments
				this.handleResize();
			});
		},
    rowNumber(index) {
      return (
        this.list.pageSize * this.list.currentPage -
        this.list.pageSize +
        index +
        1
      );
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.getTableHeight();
    },

    getTableHeight() {
      const tableTop = parseInt(
        this.$refs.btableDiv?.getBoundingClientRect()?.top
      );
      const top = parseInt(this.$refs.mainpage?.getBoundingClientRect()?.top);
      const rawHeight =
        this.window.height -
        (this.formTop > 0 ? this.formTop + 100 : tableTop) -
        top;
      const roundedHeight = Math.ceil(rawHeight / 10) * 10;
      this.tableHeight = `${roundedHeight + 23}px`;
    },

    async onSortChanged(ctx) {
      //Sort changed on Action column:
      // Fix issue where sort indicator doesn't show ascending
      // the first time we click on the action column sort
      if (this.fixSortFlag) {
        this.fetchAll();
        return;
      }
      if (ctx.sortBy === "action" || ctx.sortBy === "amazonLink") {
        // Known issue as of 2/1/22: amazonLink doesn't show up arrow when first sorting ascending;
        // however, an action column does.
        this.fixSortFlag = true;
        try {
          const el = document.querySelectorAll(".col-action");
          const attr = el[0]?.getAttribute("aria-sort");
          if (
            ctx.sortDesc === false &&
            (attr === null || attr !== "ascending")
          ) {
            // Set sort indicator up
            await el[0].setAttribute("aria-sort", "ascending");
            // this.sortOrder = false;
          } else if (attr === "ascending") {
            // Set sort indicator down
            this.sortOrder = true;
            await el[0].setAttribute("aria-sort", "descending");
          }
        } finally {
          this.fixSortFlag = false;
        }
      } else {
        // Clear action sort arrow
        const el = document.querySelectorAll(".col-action");
        //const attr = el[0]?.getAttribute("aria-sort");
        await el[0]?.setAttribute("aria-sort", "none");
        this.sortOrder = ctx.sortDesc;
        this.sortBy = ctx.sortBy;
      }
      this.fetchAll();
    },

    async onRowSelected(items) {
      await this.$store.dispatch(`${this.namespace}/listSet`, {
        key: "selectedItems",
        value: items
      });
    },

		async onRowClicked(item) {
			this.selectedRowItemId = item?.id
      await this.showItemDetail(item);
    },

    clearSelected() {
      this.$refs.btable.clearSelected();
			this.tableSelected = false;
			this.selectedRowItemId = 0;
    },

    selectAllRows() {
      if (this.tableSelected) {
        this.$refs.btable.clearSelected();
      } else {
        this.$refs.btable.selectAllRows();
      }
      this.tableSelected = !this.tableSelected;
    },
		async showFileUpload() {
			if (this.user?.tenantSelectedClient?.clientId === "0") {
				// Tenant user must select client
				this.clientSelectMsgBox("File Upload");
				return;
			}
			this.$router.push({
				path: "/file-upload",
				query: {
					namespace: this.namespace
				}
			})
		},
		clientSelectMsgBox(activity) {
			const msg = `${activity} requires a client context. Please select a specific client then try again.

					As a processor, you currently have "All clients" selected. On the main navigation bar at the top, select one of your clients.`;
			postMsgBoxOk(this, `${activity} Client Required`, msg)
			return;
		},
		toggleDetails(index) {
      this.$store.commit(`${this.namespace}/TOGGLE_SHOW_DETAILS`, { index });
      this.$store.commit(`${this.drilldownNamespace}/TOGGLE_LIST_SET`, {
        key: "toggleDrilldown"
      });
    },

		// * ******************************************************** */
    // *           SHOW/HIDE DETAIL MODAL
		// * ******************************************************** */
		async showNewItemDetail() {
			const newItem = {
				id: -1,
				clientId:  this.user?.scope === "tenant" ? this.user?.tenantSelectedClient?.clientId : this.user?.clientId,
				tenantId: this.user?.tenantId
			}
			await this.showItemDetail(newItem);
		},

		async showItemDetail(newItem) {
			this.modalItemData = newItem;
			let modalId = "";
			switch (this.namespace) {
				case 'PurchasedItem':
					modalId = 'PurchasedItemItemModal';
					break;
				case 'Issue2':
					modalId = 'Issue2ItemModal';
					break;
				default:
					throw new Error("Unknown namespace");
			}
			await handleModalShowItemDetail({ item: newItem, thisObj: this, modalId, verifyClient: true });
		},

    onModalOpen() {
			this.isModalOpen = true;
			this.justSavedModalItem = false;
			document.addEventListener('click', this.onOutsideClick);
			this.$nextTick(async () => {
				this.initDrag('PurchasedItemItemModalRef');
			});
		},
		onModalItemSaved() {
			this.justSavedModalItem = true;
		},

		async onModalClose() {
      this.isModalOpen = false;
			document.removeEventListener('click', this.onOutsideClick);
			if (this.justSavedModalItem) {
				await this.fetchAll();
			}
			this.justSavedModalItem = false;
		},

		// If purchased item shows a modal, mark it
		onChildModalStateChange({ isOpen }) {
			this.isIssueItemOpen = isOpen;
		},

		async onOutsideClick(event) {
			if (this.isIssueItemOpen) return;
			let modalWrapperRef = "";
			let modalRef = "";
			switch (this.namespace) {
				case 'PurchasedItem':
					modalWrapperRef = 'PurchasedItemItemModalRef';
					modalRef = 'PurchasedItemItemRef'
					break;
				case 'Issue2':
					modalWrapperRef = 'Issue2ItemModalRef';
					modalRef = 'Issue2ItemRef'
					break;
				default:
					throw new Error("Unknown namespace");
			}
			await handleModalOnOutsideClick(event, this, modalWrapperRef, modalRef)
    },
		cancelModal() {
      this.$bvModal.hide(`${this.$props.namespace}ItemModal`);
    },
  }
};

</script>


<style lang="css" >
/* Note that I turned off scope on CSS to get these styles to work on b-table
/* General list styles */
.row-index {
	color: #999;
}
.filter-bar {
	background-color: RGB(248, 248, 248);
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 0px;
	padding-right: 0px;
}
.table-condensed {
	font-size: 0.8em !important;
}
.table-condense tr {
	color: red;
}
.table-condensed tr.b-table-details {
	/* row detail styling */
}
#main-page {
	/* padding-left: 250px;
  padding-right: 150px; */
}
@media only screen and (max-width: 2000px) {
	#main-page {
		padding-right: 0;
	}
}
.unread-record {
	font-weight: 600;
}
th.col-action {
	/* color: blue; */
}
.col-emphasis {
	color: rgb(39, 111, 170);
	font-weight: 700;
}
.col-center {
	text-align: center;
}
.col-right {
	text-align: right;
}
.col-bold {
	font-weight: 700;
}
.col-50 {
	width: 50px;
}
.col-60 {
	width: 60px;
}
.col-70 {
	width: 70px;
}
.col-80 {
	width: 80px;
}
.col-90 {
	width: 90px;
}
.col-100 {
	width: 100px;
}
.col-110 {
	width: 110px;
}
.col-120 {
	width: 120px;
}
.col-130 {
	width: 130px;
}
.col-140 {
	width: 140px;
}
.col-150 {
	width: 150px;
}
.col-180 {
	width: 180px;
}
.col-200 {
	min-width: 180px;
}
.col-210 {
	width: 210px;
}
.col-220 {
	width: 220px;
}
.col-230 {
	width: 230px;
}
.col-240 {
	width: 240px;
}
.col-250 {
	width: 250px;
}
.col-300 {
	width: 300px;
}
.col-350 {
	max-width: 350px;
}
.col-400 {
	width: 400px;
}
.col-450 {
	width: 450px;
}
.col-500 {
	width: 500px;
}
.col-5-pct {
	width: 5%;
}
.col-7-pct {
	width: 7%;
}
.col-10-pct {
	width: 10%;
}
.col-12-pct {
	width: 12%;
}
.col-15-pct {
	width: 15%;
}
.col-16-pct {
	width: 16%;
}
.col-17-pct {
	width: 17%;
}
.col-18-pct {
	width: 18%;
}
.col-20-pct {
	width: 20%;
}
.col-30-pct {
	width: 30%;
}
.col-40-pct {
	width: 40%;
}
.col-50-pct {
	width: 50%;
}
.col-60-pct {
	width: 60%;
}
.col-70-pct {
	width: 70%;
}
.col-80-pct {
	width: 80%;
}
.col-90-pct {
	width: 90%;
}
.col-light-text {
  color: #777;
}

@media only screen and (max-width: 2000px) {
	.col-elipsis {
		/* white-space: nowrap;
    max-width: 350px;
    color: blue; */
	}
}
@media only screen and (max-width: 1700px) {
	.col-elipsis {
		/* white-space: unset;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: red; */
	}
}
.col-elipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: unset;
	padding-right: 0;
	padding-left: 0;
}

.col-red {
	color: red;
	font-weight: 600;
}
.col-warning {
	color: orange;
	font-weight: 600;
}
.col-elipsis-30 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 30px;
}
.col-elipsis-40 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 40px;
}
.col-elipsis-50 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 50px;
}
.col-elipsis-300 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 300px;
}
.col-elipsis-400 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
}
.col-elipsis-425 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 500px;
}
.col-elipsis-500 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 500px;
}
.col-350 {
	width: 350px;
}
.priority-urgent {
	background-color: #f1b0b7;
	padding: 5px;
}
.priority-important {
	background-color: #f1d3b0;
	padding: 5px;
}
.footer-row > td {
	border-top: 2px solid #ddd !important;
}
.paper-clip {
	color: #999;
	font-size: 1.1rem;
}
.showWaitClass {
	cursor: wait !important;
}
.show-detail-button-container {
	background-color: transparent;
	margin-bottom: -10px;
	padding-bottom: -10px;
	/* RGB(248, 248, 248); */
	/* border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd; */
	/* padding: 0; */
}
.row-detail-p {
	margin-bottom: 0px;
	font-size: 0.8em;
	font-weight: 700;
}

.show-detail-icon {
}
.show-detail-button {
	background-color: transparent;
	border: none;
	margin: 0px;
	padding-bottom: 0px;
	transition: 0.2s;
}
.show-detail-button:hover {
	background-color: transparent;
	transform: scale(1, 1);
}
.flip-row-detail {
	-moz-transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
.flip-row-detail-arrow {
	-moz-transform: rotate(180deg) scale(-1, 1);
	-webkit-transform: rotate(180deg) scale(-1, 1);
	-o-transform: rotate(180deg) scale(-1, 1);
	-ms-transform: rotate(180deg) scale(-1, 1);
	transform: scale(1, -1);
}
.check-header {
	font-size: 10px;
	font-weight: bold;
	padding: 0;
}
.modal-body {
	padding: 0;
}
.modal.fade .modal-dialog{
 opacity: 0;
  transform: translateY(-5px); /* Start with the modal slightly above */
  transition: opacity .3s ease-in-out, transform .3s ease-in-out; /* Animate both opacity and transform */
}
/* When the modal is shown */
.modal.fade.show .modal-dialog {
  opacity: 1;
  transform: translateY(0); /* Modal moves to its original position */
}

.issue-flag-active {
	color: orange;
}
.issue-flag-inactive {
	color: #666;
}
</style>


