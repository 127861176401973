<template>
  <b-container fluid class="item-wrapper">
    <div>
      <b-overlay
        variant="white"
        :show="isLoading && showLoading"
        :opacity="0.0"
        spinner-variant="info"
      >
        <BaseAlert :mode="mode" width="35em" top="10px" :timeout="7" />
        <b-form
          @submit.prevent="formSave"
          @keydown.enter.exact="formSave"
          @keydown.esc.exact="formCancel"
          enctype="multipart/form-data"
        >
          <!-- Main form card -->
          <b-card
            no-body
            header-tag="header"
            footer-tag="footer"
            class="no-border"
          >
            <!-- Header -->
            <template #header>
              <div class="item-modal-header-content">
                <!-- Record id -->
                <div>
                  <span class="mr-1 text-muted small">
                    {{parentId}}</span
                  >
                  <span class="small">{{ form?.id }}</span>
                </div>
                <!-- Title  -->
                <h5 class="item-header-description issue-header-container">
                  Receive Activity History and Adjustments
                </h5>
                <button
                  @click="formCancel"
                  class="item-close-btn"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
            </template>
            <!-- Card body slot -->
            <b-card no-body class="bg-light py-3 px-3">
              <div class="d-flex align-content-center">
                <BaseFormSpinner
                  id="receivedQty"
                  ref="receivedQtyRef"
                  name="receivedQty"
                  :autofocus="true"
                  label="Adjustment qty:"
                  :labelCols="mode === 'new' ? 2 : 2"
                  :value="selected?.receivedQty"
                  :state="getFieldState('receivedQty')"
                  :error="selected.error !== '' && selected.errorFieldName === 'receivedQty'"
                  :errorMsg="selected.error"
                  @input="receivedQtyInput"
                  :disabled="selected.activity!=='Adjustment'"
                  :max="999"
                  :min="initialMinQty"
                />
                <div v-if="selected.activity==='Received'" class="quantity-message">Quantity can only be chaged for adjustments</div>
              </div>
              <!-- Reason -->
              <BaseFormTextarea
                id="adjustmentReason"
                ref="adjustmentReasonRef"
                autocomplete="off"
                label="Reason:"
                :labelCols="mode === 'new' ? 2 : 2"
                :marginLeftValue="14"
                textFieldWidth="530"
                class="mr-0 pr-0"
                :value="selected?.adjustmentReason"
                :state="getFieldState('adjustmentReason')"
                @input="reasonInput"
                @keydown.enter.stop
                :error="selected.error !== '' && selected.errorFieldName === 'adjustmentReason'"
                :errorMsg="selected.error"
                size="sm"
                :rows="2"
                compact
              />
              <!-- Expected qty -->
              <div class="ml-1 mt-1">
                <!-- Expected qty -->
                <BaseFormSpinner
                  id="expectedQty"
                  label="Expected:"
                  labelFontColor="#444"
                  labelFontSize=".9rem"
                  labelFontWeight="500"
                  labelWidth="110px"
                  marginLeftValue="5px"
                  readonly
                  readonlyMarginTop="0px"
                  readonlyValueFontColor="#444"
                  readonlyValueFontSize="1.1rem"
                  readonlyValueFontWeight="600"
                  :readonlyValueRightAlign="false"
                  readonlyValueWidth="35px"
                  :value="expectedQty"
                />
              </div>
              <hr class="mt-1"/>
              <!-- Received activity table -->
              <div class="table-style">
                <b-table
                  ref="bTable"
                  :items="receiveActivityLocal"
                  :fields="receiveActivityFields"
                  :selected.sync="selected"
                  @row-selected="onRowSelected"
                  :tbody-tr-class="getRowClass"
                  small
                  hover
                  selectable
                  selectMode="single"
                  show-empty
                  bordered
                  sticky-header="true"
                  thead-class="text-smaller"
                  class="receive-adj-table-condensed"
                  thead-tr-class="base-info-table-header-class-light" >
                  <template #cell(receivedDate)="data">
                    <span v-html="data.value"></span>
                  </template>
                  <template #cell(lastUpdatedBy)="data">
                    <span v-html="data.value"></span>
                  </template>
                </b-table>
              </div>
              <!-- Quantities -->
              <div class="ml-1 mt-2">
                <!-- Total received qty -->
                <BaseFormSpinner
                  id="totalReceivedQty"
                  label="Total received:"
                  labelFontColor="#444"
                  labelFontSize=".9rem"
                  labelFontWeight="500"
                  labelWidth="110px"
                  marginLeftValue="5px"
                  readonly
                  readonlyMarginTop="10px"
                  readonlyValueFontColor="#444"
                  readonlyValueFontSize="1.1rem"
                  readonlyValueFontWeight="600"
                  :readonlyValueRightAlign="false"
                  readonlyValueWidth="35px"
                  :value="totalReceivedQty"
                />
                <!-- Total issue qty -->
                 <div class="mb-3">
                  <BaseFormSpinner
                    id="totalIssueQty"
                    label="Total issue qty:"
                    labelWidth="115px"
                    readonly
                    :value="totalIssueQty"
                    :readonlyValueRightAlign="false"
                    readonlyValueWidth="35px"
                  />
                </div>
                <!-- Pending qty -->
                <BaseFormSpinner
                  id="pendingQty"
                  label="Pending:"
                  labelFontColor="#444"
                  labelFontSize=".9rem"
                  labelFontWeight="500"
                  labelWidth="110px"
                  marginLeftValue="5px"
                  readonly
                  readonlyMarginTop="10px"
                  readonlyValueFontColor="#444"
                  readonlyValueFontSize="1.1rem"
                  readonlyValueFontWeight="600"
                  :readonlyValueRightAlign="false"
                  readonlyValueWidth="35px"
                  :value="pendingQty"
                />
              </div>
            </b-card>
            <!-- Card footer slot -->
            <template #footer>
                <div class="d-flex justify-content-end">
                  <div class="d-flex">
                    <b-button
                      class="mr-3"
                      variant="secondary"
                      @click="formCancel"
                      >{{ isFormDirty ? "Cancel" : "Close" }}</b-button
                    >
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="
                        !isFormDirty
                      "
                      v-if="isAuthorized('Issue', 'update')"
                      >Save</b-button
                    >
                  </div>
              </div>
            </template>
          </b-card>
        </b-form>
      </b-overlay>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { integer } from "vuelidate/lib/validators";
 import {
  handleConfirmChanges,
  handleShowAlert,
} from "../../utils/component-utils";
import config from "../../../config.js"

export default {
  components: {
  },
  name: "ReceiveAdj",
  props: {
    parentId: { type: Number, default: null },
    parentClientId: { type: String, default: null },
    parentTenantId: { type: String, default: null },
    expectedQty: { type: Number, default: null },
    totalIssueQty: { type: Number, default: null },
  },
  data() {
    return {
      mode: "new",
      form: {
        receivedQty: 0,
        adjReason: "",
      },
      receivedActivityOrig: [],
      selected: [],
      selectedIndex: 0,
      selectingRowFlag: false,
      formSaved: false,
      totalReceivedQty: 0,
      totalReceivedQtyOrig: 0,
      initialMinQty: 0,
    };
  },
  validations: {
    form: {
      receivedQty: {integer},
      adjReason: {},
    },
  },
  async created() {
    this.receivedActivityOrig = JSON.parse(JSON.stringify(this.receiveActivityLocal));  
    this.insertNewRow();
    this.calculateIinitialMinQty();
  },
  mounted() {
    this.$nextTick(() => {
      this.selectFirstRow();
      if (this.$refs.receivedQtyRef) {
        this.$refs.receivedQtyRef.$el.focus();
      }
    });
    this.calculateTotalReceivedQty(true);
  },
  watch: {
  },
  computed: {
    ...mapGetters("Session", [
      "isLoading",
      "showLoading",
      "isAuthorized",
      "user",
      "authToken",
    ]),
    receiveActivityLocal() {
      return this.$store.getters[`PurchasedItem/receiveActivity`]
        .map(item => ({
          ...item,
          activity: item.isAdjustment ? "Adjustment" : "Received"
        }))
        .slice()
        .sort((a, b) => new Date(b.ts) - new Date(a.ts));
    },
    receiveActivityFields() {
      return [
        {
          key: "activity",
          label: "Activity",
          class: "col-17-pct"
        },
        {
          key: "receivedQty",
          label: "Qty",
          class: ["col-center", "col-7-pct"],
        },
        {
          key: "receivedDate",
          label: "Date",
          sortable: true,
          class: ["col-right", "col-12-pct" ],
          formatter: (value, key, item) => {
            if (item.ts) {
              const dateObj = new Date(item.ts);
              const date = dateObj.toLocaleDateString(); // Formats date part
              const time = dateObj.toLocaleTimeString(); // Formats time part
              return `<div class="small-text col-light-text">${date}</div><div class="small-text col-light-text">${time}</div>`;
            }
            return "";
          },
        },
        {
          key: "lastUpdatedBy",
          label: "By",
          sortable: true,
          class: ["col-15-pct"],
          formatter: (value) => {
            if (value) {
              const [localPart, domain] = value.split('@');
              return `<div class="small-text col-light-text">${localPart}</div><div class="small-text col-light-text">@${domain}</div>`;
            }
            return "";
          },
        },
        {
          key: "adjustmentReason",
          label: "Adjustment Reason",
        },
      ];
    },

    pendingQty() {
      return Number(
        (Number(this.expectedQty) || 0) -
          (Number(this.totalReceivedQty) || 0) -
          (Number(this.totalIssueQty) || 0)
      );
    },

    isFormDirty() {
      return this.$v.form.$anyDirty;
    },
  },
  methods: {
    isOriginalItem(item) {
      // Find the corresponding item in the original list by an identifier, like ID
      return this.receivedActivityOrig.some(orig => orig.id === item.id);
    },

    calculateIinitialMinQty() {
      // const totalExcludingSelected = this.receiveActivityLocal.reduce((total, item) =>
      //   total + (item === this.selected ? 0 : (item.receivedQty || 0)), 0
      // );
      // Calculate the minimum quantity to keep total received quantity >= 0
      // const remainingQty = this.totalReceivedQtyOrig - totalExcludingSelected;
      if (config.app.allowNegativeTotalReceived) {
        this.initialMinQty = -999;
      } else {
        //        this.initialMinQty = remainingQty;
        this.initialMinQty = this.form.receivedQty - this.totalReceivedQtyOrig;
      }
    },

    calculateTotalReceivedQty(firstTime) {
      this.totalReceivedQty = this.receiveActivityLocal.reduce((total, item) => total + (item.receivedQty || 0), 0);
      if (this.totalReceivedQty < 0) {
        if (!config.app.allowNegativeTotalReceived) {
          // Note: Allowing user to set total received qty < 0 is configurable in .env and config.
          // Default is false (cannot go below zero);
          this.totalReceivedQty = 0;
        }
      }
      if (firstTime) {
        this.totalReceivedQtyOrig = this.totalReceivedQty;
      }
    },
    
    insertNewRow() {
      const newRow = {
        id: -1,
        clientId: this.parentClientId,
        tenantId: this.parentTenantId,
        purchasedItemId: this.parentId,
        activity: "Adjustment",
        receivedQty: 0,
        ts: new Date().toISOString(),
        lastUpdatedBy: this.user.userId,
        createdBy: this.user.userId,
        isAdjustment: true,
        adjustmentReason: ""
      };
      this.receiveActivityLocal.unshift(newRow);
    },
    receivedQtyInput(value) {
      this.selected.error = null;
      this.selected.errorFieldName = null;
      this.selected.receivedQty = value;
      if (!this.selectingRowFlag) {
        this.form.receivedQty = value;
        this.$v.form.receivedQty.$touch();
      }
      this.selected.isModified = true;
      this.calculateTotalReceivedQty();
    },
    reasonInput(value) {
      this.selected.error = null;
      this.selected.errorFieldName = null;
      this.selected.adjustmentReason = value;
      this.selected.isModified = true;
      if (!this.selectingRowFlag) {
        this.form.adjReason = value;
        this.$v.form.adjReason.$touch();
      }
    },
    selectFirstRow() {
      this.$refs.bTable?.selectRow(0)
    },

    async onRowSelected(selectedItem) {
      if (this.selectingRowFlag) return;
      try {
        this.selectingRowFlag = true;
        const saveIsFormDirty = this.$v.$anyDirty;
        if (selectedItem && selectedItem.length > 0) {
          // Valid row selected. Remember index
          this.selectedIndex = this.receiveActivityLocal.findIndex(
            item => item === selectedItem[0]
          );
          this.selected = selectedItem[0];
          this.form.receivedQty = await this.selected?.receivedQty;
          this.form.adjReason = await this.selected?.adjustmentReason;
        } else {
          // User unselected row
          this.selected = this.receiveActivityLocal[this.selectedIndex]
          this.$refs.bTable?.selectRow(this.selectedIndex)
        }
        this.calculateIinitialMinQty();
        if (this.formSaved) {
          this.validationErrors();
        }
        if (!saveIsFormDirty) {
          this.$v.$reset();
          this.$v.form["receivedQty"].$reset();
          this.$v.form.adjReason.$reset();
        }
      } finally {
        this.selectingRowFlag = false;
        // Focus on component
        setTimeout(() => {
          if (this.selected.activity === "Adjustment") {
            this.$refs.receivedQtyRef.$el.querySelector("input")?.focus();
          } else {
            this.$refs.adjustmentReasonRef.$el.querySelector("textarea")?.focus();
          }
        }, 0)
      }
    },

    async formCancel() {
      if (this.$v.form.$anyDirty) {
        this.confirmingChanges = true;
        const reply = await handleConfirmChanges(this);
        this.confirmingChanges = false;
        if (reply === null || typeof reply === "undefined") {
          return;
        } else if (reply) {
          // Yes, save before leaving
          await this.formSave();
        } else {
          // No. Don't save before cancel
          this.$emit("cancelModal");
        }
      } else {
        this.$emit("cancelModal");
      }
    },

    async formSave() {
      this.formSaved = true;
      if (this.validationErrors()) {
        // Errors
        this.$forceUpdate();
        this.selectedIndex = this.receiveActivityLocal.findIndex(
          item => item.error && item.error.trim() !== ""
        );
        this.$refs.bTable?.selectRow(this.selectedIndex)
        handleShowAlert(this);
        return;
      }
      // Remove blank adjustments (0 qty and no reason)
      const filteredData = this.receiveActivityLocal.filter(
        item => !(item.receivedQty === 0 && item.adjustmentReason === "")
      );
      // Commit changes to vuex
      this.$store.commit(`PurchasedItem/PAST_RECEIVE_ACTIVITY_SET`, filteredData);
      this.$v.$reset();
      this.$emit("save-receive-item")
      this.$emit("cancelModal");
    },

    validationErrors() {
      let error = false;
      this.receiveActivityLocal.forEach((item) => {
        // Reset any previous errors
        item.error = null;
        // Check for new rows (id = -1)
        if (item.id === -1) {
          if (item.receivedQty !== 0 && !item.adjustmentReason) {
            item.error = "Reason is required if adjusted qty is not 0";
            item.errorFieldName = "adjustmentReason"
            error = true;
          }
          if (item.adjustmentReason && item.receivedQty === 0) {
            item.error = "Adjustment quantity is required if a reason is provided";
            item.errorFieldName = "receivedQty"
            error = true;
          }
        } else {
          // Check for existing rows (id != -1)
          if (item.activity === "Adjustment") {
            if (!item.adjustmentReason) {
              item.error = "Reason is required if adjusted qty is not 0";
              item.errorFieldName = "adjustmentReason"
              error = true;
            }
            if (item.receivedQty === 0) {
            item.error = "Adjustment quantity is required if a reason is provided";
            item.errorFieldName = "receivedQty"
              error = true;
            }
          }
        }
      });
      this.$forceUpdate();
      // Return errors array, or null if no errors
      return error;
    },

    getRowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.error) {
        return 'text-danger'
      }
    },

    getFieldState(value) {
      if (this.selected.error && this.selected.error !== '' && this.selected.errorFieldName === value) {
        return false;
      }
      return null;
    },
  },

};

</script>

<style scoped>

.b-card {
  position: relative;
}

.reason-label {
  width: 300px;
}

#adjReason-input {
  margin-right: 0 !important;
  display: inline-block;
}

.receive-adj-table-condensed {
	font-size: 0.8em !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 2px !important;
  
}

.base-info-table-header-class th{
  background-color: #666 !important;
}

.row-error{
  color: red !important;
}

.quantity-message {
  margin-left: 10px;
  font-size: .8rem;
  font-style: italic;
  color: #666;
  margin-top: 3px;
}

.table-style {
  border: 1px solid #aaa;
  padding: 0;
  border-radius: 2px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }

</style>