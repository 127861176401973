<template>
  <div id="app"  class="app-style">
    <div class="sticky-top">
      <Navbar v-if="isAuthenticated" />
    </div>
    <div>
      <router-view class="" :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import Navbar from "./views/navigation/Navbar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters("Session", ["isAuthenticated", "subNav"]),
    toastMessage() {
      return this.$store.state["Notification"].toast.message;
    },
    toast() {
      return this.$store.state["Notification"].toast;
    }
  },
  watch: {
    toastMessage: function() {
      this.toastShow();
    }
  },
  methods: {
    async toastShow() {
      // Toast message from store

      this.$bvToast.toast(this.toastMessage, {
        title: this.toast.title,
        toaster: this.toast.toaster,
        variant: this.toast.variant,
        noAutoHide: this.toast.noAutoHide,
        duration: this.toast.duration
      });
      this.$store.dispatch("Notification/toastErrorAdd", "", { root: true });
    }
  }
};
</script>

<style lang="css" scoped>
@import "./App.css";

.app-style {
  background-color: #eee;
}
</style>