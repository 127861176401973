<template>
  <div>
    <span  @click="openModal" title="More information">
      <i class="fas fa-info-circle"></i>
    </span>
    <BaseInformationModal
      :modal-id="modalId" 
      :heading="heading"
      :text="text"
      @close="handleClose"
    />
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalId: 'info-modal-' + Math.random().toString(36).substr(2, 9), // Generate unique ID
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modalId); // Show modal by ID
    },
    handleClose() {
      this.$bvModal.hide(this.modalId); // Hide modal by ID
    },
  },
};
</script>

<style scoped>
.info-icon {
  font-size: 20px;
  height: 20px !important;
  width: 20px !important;
  padding: 0;
  color: #007bff;
  cursor: pointer;
  border-radius: 50%;
  transition: color 0.3s ease;
  box-shadow: 2px 2px 5px rgba(14, 15, 14, 0.241);
}

.info-icon:hover {
  color: #0056b3; /* Darker blue on hover */
}
</style>
