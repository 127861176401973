<template>
  <b-overlay :show="isLoading" :opacity="0.5" class="full-screen-overlay">
    <BaseLogo class="fixed-top p-2" fontSize="1.4em" v-if="!isAuthenticated" />
    <div >
      <BaseAlert :mode="mode" :timeout="7" width="350px" />
      <b-form @submit.prevent="onSubmit" @keydown.enter.exact="onSubmit">
        <!-- Main form card -->
        <b-card class="shadow-sm main-card" header-tag="header" footer-tag="footer">
          <!-- Header -->
          <template #header>
            <span class="d-flex justify-content-center">
              <h4 class="mb-0 ">Please Login</h4>
            </span>
          </template>
          <!-- Email -->
          <BaseFormInput
            class="mt-3"
            id="userId"
            type="email"
            label="Email:"
            description="Enter your email"
            v-model="userId"
            :state="validateState('userId')"
            :error="$v.userId.$error"
            errorMsg="User id is required and must be a valid email"
            autofocus
          />
          <!-- icon="envelope" -->
          <!-- Password -->
          <BaseFormInput
            id="password"
            type="password"
            label="Password:"
            description="Enter password"
            v-model="password"
            :state="validateState('password')"
            :error="$v.password.$error"
            errorMsg="Password is required"
          />
          <!-- icon="lock" -->

          <b-button
            type="submit"
            block
            variant="info"
            class="mb-3 mt-5 font-weight-bold"
            >Login
          </b-button>
          <!-- <b-spinner label="Loading..."></b-spinner> -->
          <!-- Links at bottom -->
          <div class="d-flex justify-content-between">
            <b-link href="#" disabled>Forgot password?</b-link>
            <b-link href="#" disabled>Request access</b-link>
          </div>
        </b-card>
      </b-form>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { handleRouterPushError } from "../../utils/component-utils.js";

export default {
  components: {
    // PulseLoader
  },
  name: "Login",
  props: {
    namespace: { type: String, default: "Session" },
    mode: { type: String, default: "new" }
  },
  data() {
    return {
      userId: "",
      password: ""
    };
  },
  validations: {
    userId: { required, email },
    password: { required }
  },
  computed: {
    // ...mapState("Session", ["isLoading"]),
    // ...mapGetters({
    //   isLoading: "Session/isLoading",
    //   isAuthenticated: "Session/isAuthenticated"
    // }),
    ...mapGetters("Session", [
      "isLoading",
      "isAuthorized",
      "isAuthenticated",
      "dashboardPath"
    ])
  },
  methods: {
    ...mapActions("Session", ["login", "toDashboard"]),

    async onSubmit() {
      // Alert if any validation errors
      this.$v.$touch();
      if (this.$v.$anyError) {
        const msg = "Please fix errors before submitting.";
        this.$store.dispatch(
          "Notification/alertErrorAdd",
          { message: msg, show: 7 },
          { root: true }
        );
      }
      if (!this.$v.$invalid) {
        try {
          // Login
          await this.login({
            userId: this.userId,
            password: this.password
          });
          // Route to dashboard after login
          this.toDashboard();
          this.$router.push({ path: this.dashboardPath }).catch(err => {
            handleRouterPushError(err);
          });
        } catch (error) {
          // Stay here
          console.log("error:", error?.data?.error);
          const msg = error?.data?.error ? error?.data?.error : error;
          this.$store.dispatch(
            "Notification/alertErrorAdd",
            { message: msg, show: 7 },
            { root: true }
          );
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      if (name === "password") {
        return $dirty && $error ? false : null;
      }
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style lang="css" scoped>
form {
  width: 350px;
  margin: auto;
}

.full-screen-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding-top: 120px;
}

 .main-card {
  border-radius: 10px;
 }

.main-card .card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
