<template>
  <!-- Issues List -->
  <div class="issues-list-container">
    <div class="issues-list-header"  :class="{ 'issues-list-header-active': activeIssueQty > 0}"
>
        <span style="font-weight: 500">Issues</span>
        <div class="issues-list-total-qty">
          <span class="mr-2" style="font-size: .8rem">Active issue qty: </span>
          <span style="margin-top: 1px; font-weight: 500">{{ activeIssueQty || 0 }}</span>
        </div>
        <div class="issues-list-total-qty">
          <span class="mr-2" style="font-size: .8rem">Total issue qty: </span>
          <span style="margin-top: 1px; font-weight: 500">{{ totalIssueQty || 0 }}</span>
        </div>
    </div>
    <div class="issues-scrollable-list">
      <div>
        <p v-if="issueCount===0" class="no-issues mx-auto">No linked issues</p>
      </div>
      <!-- Issues List -->
      <div v-for="(item, index) in localIssues" :key="index">
        <!-- Each item -->
        <div class="item-container" @click="onItemClick(item)">
          <div class="row-style">
            <!-- Left (qty) column -->
            <div class="col-1 d-flex justify-content-left">
              <!-- Issue qty -->
              <div class="qty-container">
                <div class="label-font">Qty</div>
                <div class="field">{{ item.issueQty }}</div>
              </div>
            </div>
            <!-- Right main column -->
            <div class="col-2">
              <b-row class="row-style">
                <!-- Reason/type -->
                <div class="field-container">
                  <div class="label-right-align">Reason:</div>
                  <span class="fixed-field-left" >{{ item.issueType.typeName }}</span>
                </div>
                <!-- Status -->
                <div class="field-container">
                  <span class="label-right-align">Status:</span>
                  <span class="fixed-field-right">{{ item.issueStatus.statusName }}</span>
                </div>
                <!-- Open flag -->
                <div v-if="!item.issueStatus.closedOption" class="">
                    <i v-b-tooltip.hover title="Active issue" class="fas fa-flag issue-flag-active"></i>
                </div>
                <!-- Id -->
                <span class="label-font ml-auto">#{{ item?.id }}</span>
              </b-row>
              <b-row class="row-style">
                <!-- Action -->
                <div class="field-container">
                  <span class="label-right-align">Action:</span>
                  <span class="fixed-field-left" >{{ item.issueAction?.actionName }}</span>
                </div>
                <!-- Assignee -->
                <div class="d-flex justify-content-between align-items-center">
                  <div class="field-container">
                    <span class="label-right-align">To:</span>
                    <span class="field" style="width: 90px">{{ item.authUser.familiarName }}</span>
                  </div>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    issueItems: []
  },
    data() {
    return {
      localIssues: this.issueItems,
    };
  },
  watch: {
    issueItems: {
      immediate: true,
      deep: true,
      handler(newIssues) {
        this.localIssues = newIssues;
      },
    },
  },
  computed: {
    issueCount() {
      return this.localIssues?.length || 0;
    },
    totalIssueQty() {
      return this.localIssues?.reduce((total, issue) => total + (issue.issueQty || 0), 0) || 0;
    },
    activeIssueQty() {
      return this.localIssues?.reduce((total, issue) => {
        return issue?.issueStatus?.closedOption !== true ? total + (issue.issueQty || 0) : total;
      }, 0);
    },
  },
  methods: {
    onItemClick(item) {
      this.$emit("issueClick", item);
    }      
  },
};
</script>

<style scoped>
/* *********************************************
/          Issues List
/  ********************************************* */
.issues-list-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100%;
}

.issues-list-header, .issues-list-header-active {
  display: flex;
  align-items: center;
  justify-content:space-between;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e9e9e9;
  border: 1px solid rgb(204, 204, 204);
}

.issues-list-header-active {
  background-color: #ffe599;
  border: 1px solid orange;
}

.issues-list-total-qty {
  display: flex;
  align-items: center;
}

.issues-scrollable-list {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100% - 37px);
}

.no-issues {
  font-size: .9rem;
  font-style: italic;
  color: #888;
  text-align: center;
}

/* *********************************************
/          Issue Item
/  ********************************************* */
.item-container {
  background-color: white;
  margin-bottom: 10px;
  border: 1px solid, #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 3px rgba(199, 213, 225, 0.6); /* Optional: Add a glow effect */
}

.item-container:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.item-container:active {
  transform: translateY(2px); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
}

.row-style {
  display: flex;
  align-items: center;
}

.col-1 {
  flex: 0 0 8%;
  max-width: 8%;
}

.col-2 {
  flex: 1;
  max-width: 100%;
}

.field-container {
  display: flex;
  align-items: baseline;
  /* border: 1px solid red; */
}

.label-font, .label, .label-right-align {
  color: #999;
  font-size: 12px;
}

.label, .label-right-align{
  margin-right: 5px;

}

.label-right-align {
  text-align: right;
  width: 45px;
}

.field, .fixed-field, .fixed-field-right, .fixed-field-left {
  font-size: 13px;
}

.fixed-field, .fixed-field-right, .fixed-field-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-field-right {
  width: 45px;
}

.fixed-field-left {
  width: 140px;
}

.qty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: -12px;
}

.issue-flag-active {
	color: orange;
  font-size: .9rem;
}

</style>
