<template class="template">
  <b-overlay
    variant="white"
    :show="isLoading"
    :opacity="0.0"
    spinner-variant="light"
    spinner-type="none"
    class="mx-auto sidebar-home-overlay"
  >
    <div class="sidebar-wrapper d-flex" >
      <!-- Show sidebar -->
      <b-button
        size="sm"
        class="sidebar-unhide-button"
        variant="light"
        v-if="sidebarHidden"
        @click.prevent="unhideSidebar"
      >
        <i class="fas fa-thumbtack unpin-icon sidebar-hide-icon"></i>
      </b-button>
      <!-- Side nav for Purcahsed Items Receive -->
      <div
        class="sidebar-body p-1 sidebar-border"
        :class="{ 'sidebar-hidden': sidebarHidden }"
      >
        <b-nav vertical class="sidebar-nav sidebar">
          <!-- Hide sidebar button -->
          <b-button
            size="sm"
            variant="light"
            class="
              ml-auto
              mb-0
              sidebar-hide-button 
            "
            @click.prevent="hideSidebar"
          >
            <i class="fas fa-thumbtack sidebar-hide-icon"></i>
          </b-button>
          <!-- Header -->
          <div class="pl-3">
            <h5>Purchased Items</h5>
          </div>
          <hr class="mt-1" />
          <!-- Pending Receive List -->
          <b-nav-item
            ref="rec-oar"
            exact-active-class="nav-item-active"
            :to="{ name: 'PurchasedItemReceivePending' }"
          >
            Items Received - Pending</b-nav-item
          >
          <!-- Received All -->
          <b-nav-item
            ref="rec-oar"
            exact-active-class="nav-item-active"
            :to="{ name: 'PurchasedItemReceiveAll' }"
          >
            Items Received - All</b-nav-item
          >
          <!-- Pending >21 days -->
          <b-nav-item
            ref="rec-oar"
            exact-active-class="nav-item-active"
            :to="{ name: 'PurchasedItemReceive21d' }"
          >
            Items Received > {{ pendingDays }} days</b-nav-item
          >
        </b-nav>
      </div>
     <!-- Main Content Area -->
      <div id="purchasedItemMain" class="main-content p-0 flex-grow-1 m-0">
        <BaseBreadcrumb class="border-left"/>
        <router-view></router-view> 
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../../../config"

// Bootstrap sidebar menu
export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "PurchasedItem",
      refreshTooltip: "Click to refresh summary",
      pendingDays: config.pendingDays
    };
  },
  async created() {},
 mounted() {
  },
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized"]),
    totalPending: function() {
      return this.pendingAging
        .reduce((acc, curr) => acc + Number(curr.pendingQty), 0)
        .toLocaleString();
    },
    sidebarHidden: {
      set(sidebarHidden) {
        this.$store.dispatch("PurchasedItem/sidebarHidden", sidebarHidden);
      },
      get() {
        return this.$store.state["PurchasedItem"].sidebarHidden;
      }
    }
  },
  methods: {
    setTitle(text) {
      this.$store.dispatch(`Session/setSubNav`, text);
    },
    async refreshRs() {
      await this.$store.dispatch(`${this.namespace}/fetchReceiveSummary`);
    },
    async refreshPa() {
      await this.$store.dispatch(`${this.namespace}/fetchPendingAging`);
    },
    hideSidebar() {
      this.sidebarHidden = true;
    },
    unhideSidebar() {
      this.sidebarHidden = false;
    }
  }
};
</script>

<style lang="css" >

</style>