<template>
  <div  :style="cssVars" class="label-value-group">
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-select
        :id="`${id}-input`"
        :name="`${id}-input`"
        :ref="`${id}-input`"
        :options="getOptions()"
        @change="changeValue(id, $event)"
        v-bind="$attrs"
        :text-field="textField"
        :value-field="valueField"
        :plaintext="plaintext"
        :disabled="readonly || disabled"
        :required="required"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
      >
      <template #first v-if="firstOption">
        <b-form-select-option :value="null" :disabled="firstOptionDisabled">{{ firstOptionText }}</b-form-select-option>
      </template>

      </b-form-select>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    namespace: String,
    fieldName: String,
    compact: { type: Boolean, default: false },
    errorMsg: { type: String },
    id: { type: String },
    description: { type: String },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean },
    firstOption: { type: [Boolean], default: false },
    firstOptionText: { type: String, default: "" },
    firstOptionDisabled: { type: [Boolean], default: false },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    marginLeft: { type: String, default: "" },
    marginRight:{ type: String, default: "" },
    marginLeftValue: { type: String, default: "" },
    options: { type: [Array, String], default: ()=>([]) },
    optionsField: { type: String, default: "options" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    textFieldWidth: { type: String, default: "" },
    textField: {type: String, default: ""},
    uppercase: { type: Boolean, default: false },
    valueField: { type: String, default: "" },
  },

  computed: {
    filters() {
      return this.$store.getters[`${this.namespace}/filters`];
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--margin-right": this.marginRight,
        "--margin-left": this.marginLeft,
      };
    }
  },

  methods: {
    changeValue(id, $event) {
      this.$emit("g3Change", { field: id, value: $event });
    },
    getOptions() {
      if (this.options && this.options.length > 0) {
        // Use passed in options
        return this.options;
      } else {
        // Get options from store
        const filters = this.filters;
        const filter = filters?.find(filter => filter.field === this.fieldName);
        return filter[this.optionsField] ? filter[this.optionsField] : this.options;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}

.label-value-group {
  margin-right: var(--margin-right);
  margin-left: var(--margin-left);
}
</style>
