import * as baseStore from "./base2.store";
// import { getPastDateInUTC } from "../../utils/date-utils.js";
import * as purchasedItemModel from "../../views/purchased-items/purchased-item-model.js";
import config from "../../../config.js";

const initialState = () => {
  return {
    ...baseStore.state,
    list: {
      allowInsert: false,
      amazonLinkField: "asin",
      amazonUrlField: "amazonUrl",
      currentPage: 1,
      documentTitle: "g3tools - Purchased Items Receive",
      field: [],
      imageField: "imageUrl",
      keyField: "",
      listName: "Purchased Item Receive - Pending",
      orderClause: "",
      pageSize: 50,
      role: "PurchQp",
      routeName: "PurchasedItemReceivePending",
      searchText: "",
      selectedItems: 0,
      selectMode: "single", // multi, single, range
      selectRowShowsDetail: true,
      showAdd: true,
      showDelete: false,
      showOtherActions: false,
      sortBy: "ts",
      sortOrder: "DESC",
      totalRows: 0,
    },
    entityDescription: "Purchased Item Receive",
    subNavTitle: "Purchased Item Receive",
    sidebarHidden: false,
    filters: purchasedItemModel.getFilters(config.SCOPES.CLIENT),
    currentUploadMap: purchasedItemModel.sorteduploadFieldsMap(),
    savedMaps: [],
    receiveActivity: [],
    relatedIssues: [],
  };
};

// // Instantiate base store, passing in root endpoint and state
// const base = new BaseStore("purch-item", useState);
// let baseState = { ...base.state };

// State object instantiated so we can easily reset using initialState
// const initialState = () => {
//   return {
//     // State from base store with overridden values
//     ...baseState,
//   };
// };

const state = initialState();

// Getter functions
const getters = {
  // Standard getters from base store added here
  ...baseStore.getters,
  currentItem: (state) => state.currentItem,
  isItemLoaded: (state) => {
    return !!state.currentItem;
  },
  list: (state) => state.list,
  filters: (state) => state.filters,
  items: (state) => state.items,
  comments: (state) => state.comments,
  attachments: (state) => state.attachments,
  currentUploadMap: (state) => state.currentUploadMap,
  savedMaps: (state) => state.savedMaps,
  receiveActivity: (state) => state.receiveActivity,
  relatedIssues: (state) => state.relatedIssues,
};

// Actions
const actions = {
  ...baseStore.actions,
  async initializeStore({ commit, rootState }) {
    // Set fields and filters based on scope
    const useScope = rootState.Session.scope;
    const scopedFields = purchasedItemModel.getFields(useScope);
    commit("SET_FIELDS", scopedFields);
    const scopedFilters = purchasedItemModel.getFilters(useScope);
    commit("SET_FILTERS", scopedFilters);
  },
  async clearFilters({ state, commit, dispatch }, { fetchOnStart }) {
    // Clear sort
    await commit("LIST_SET", {
      key: "sortBy",
      value: "ts",
      //      value: childState.list.sortBy || "",
    });
    await commit("LIST_SET", {
      key: "sortOrder",
      value: "DESC",
      //      value: childState.list.sortOrder || "",
    });
    // Set filters based on route
    await dispatch("baseClearFilters", {
      endpoint: "/purch-item",
      fetchOnStart,
      childState: state,
    });
    await dispatch("baseFetchAll", {
      endpoint: "/purch-item",
      childStateList: state.list,
      childStateFilters: state.filters,
    });
  },
  // Override clearFilterOnly
  async clearFiltersOnly({ state, commit, dispatch }) {
    // Set filters based on route
    await dispatch("dynamicFilters");
    await baseStore.actions.baseClearFiltersOnly({ state, commit, dispatch });
  },
  dynamicFilters(context) {
    purchasedItemModel.dynamicFilters(state, context);
  },

  async createNewItem({ dispatch, commit, rootState }) {
    commit("ISSUE_ITEM_RESET");
    await dispatch("basePrepareCurrentItem");
    const newIssue = purchasedItemModel.defaultIssue(rootState.Session.user);
    commit("CURRENT_ITEM_SET", newIssue);
    await dispatch("addComment");
    // Get options
    const endpoint = "/purch-item/options";
    await dispatch("baseGetOptions", endpoint);
  },

  // Use the shared fetchItem action from base.store
  //  async fetchItem({ dispatch, commit, state }, itemId) {
  async fetchItem({ dispatch, commit, state }, props) {
    const { item, tenantId, clientId } = props;
    commit("PURCH_ITEM_RESET");
    const response = await dispatch("baseFetchItem", {
      endpoint: "/purch-item",
      item,
      tenantId,
      clientId,
      state,
    });
    // Handle received activity and related issues
    const receiveActivity = response.data?.itemsReceived;
    const relatedIssues = response.data?.issues;
    if (receiveActivity && receiveActivity.length > 0) {
      commit("PAST_RECEIVE_ACTIVITY_SET", receiveActivity);
    } else {
      commit("PAST_RECEIVE_QUANTITY_CLEAR");
    }
    if (relatedIssues && relatedIssues.length > 0) {
      commit("RELATED_ISSUES_SET", relatedIssues);
    }
  },

  async fetchRelatedIssues(
    { dispatch, commit },
    { purchasedItemId, issueItem }
  ) {
    const url = `/purch-item/${purchasedItemId}/related-issues?tenantId=${issueItem.tenantId}&clientId=${issueItem.clientId}`;
    const response = await dispatch("baseFetchAllGeneral", url);
    // Handle received activity and related issues
    const relatedIssues = response;
    if (relatedIssues && relatedIssues.length > 0) {
      commit("RELATED_ISSUES_SET", relatedIssues);
    }
  },

  async fetchAll({ dispatch, state }) {
    await dispatch("baseFetchAll", {
      endpoint: "/purch-item",
      childStateList: state.list,
      childStateFilters: state.filters,
    });
  },

  //async itemCreate({ dispatch, state }, item) {},

  async itemSaveUnreadFlag({ dispatch }, id) {
    if (state.currentItem?.id !== id) {
      throw new Error("Current item doesn't match expected item id");
    }
    const payload = {
      id: id,
      tenantId: state.currentItem?.tenantId,
      clientId: state.currentItem?.clientId,
    };
    await dispatch("baseItemSave", {
      endpoint: "/purch-item/unread",
      record: payload,
    });
  },

  //   Specific actions here
  async itemSave({ dispatch, commit }, record) {
    // Add receive qty to logEvent
    commit("LOG_EVENT_SET", {
      key: "addReceivedQty",
      value: record.receiveQty,
    });
    // Add recievedQty to copy so it will be considered changed
    commit("CURRENT_ITEM_COPY_FIELD_SET", { key: "receivedQty", value: 0 });
    // Combine currentItem
    commit("CURRENT_ITEM_SET_ASSIGN", record);
    // Save purchased item to database
    const updatedRecord = await dispatch("baseItemSave", {
      endpoint: "/purch-item",
      record,
    });
    // Received activity (adjustments) to database
    const filteredData = state.receiveActivity?.filter(
      (item) => item.id === -1 || item.isModified === true
    );
    if (filteredData && filteredData.length > 0) {
      // Save to database
      await dispatch("basePostUpsert", {
        endpoint: "/purch-item/receive-activity",
        data: filteredData,
      });
    }
    commit("CURRENT_ITEM_SET_ASSIGN", updatedRecord.data);
  },

  // Dropdown filter set
  async setFilterSelectedItems(context, payload) {
    context.commit("FILTER_SELECTED_SET", payload);
  },

  async fetchUploadMaps({ dispatch, commit }) {
    commit("LOAD_UPLOAD_MAP", []);
    const uploadMaps = await dispatch(
      "baseFetchAllGeneral",
      `/upload-map?listName=Purchased Item`
    );
    commit("LOAD_UPLOAD_MAP", uploadMaps?.data);
  },

  async fetchExtraOptions({ dispatch }) {
    await dispatch("baseFetchExtraOptions", "/upload-map/extra-options");
  },

  async uploadMapTitleSave({ state, dispatch, commit }, payload) {
    const id = payload.id;
    const name = payload.title;
    // Save existing map record
    commit("UPDATE_UPLOAD_MAP_TITLE", { id, name });
    const foundRec = state.savedMaps.find((f) => f.id === id);
    if (foundRec) {
      await dispatch("baseUploadMapUpsert", foundRec);
    }
  },

  async uploadMapSave({ state, dispatch, commit }, payload) {
    const map = payload;
    if (payload?.id === -1) {
      // Create new map record
      const result = await dispatch("baseUploadMapUpsert", map);
      commit("ADD_UPLOAD_MAP", result?.data);
      return result?.data;
    } else {
      // Save existing map record
      const foundRec = state.savedMaps.find((f) => f.id === map.id);
      if (foundRec) {
        const result = await dispatch("baseUploadMapUpsert", foundRec);
        commit("UPDATE_UPLOAD_MAP_RECORD", result.data);
      }
    }
  },

  async uploadMapDelete({ dispatch, commit }, item) {
    commit("DELETE_UPLOAD_MAP", item.id);
    await dispatch("baseUploadMapDelete", item);
  },

  async uploadCsvFile({ dispatch }, { uploadData, filename, allowDuplicates }) {
    const endpoint = `/purch-item/upload-csv?filename=${filename}&allowDuplicates=${allowDuplicates}`;
    const result = await dispatch("baseUploadCsvFile", {
      endpoint,
      uploadData,
      filename,
    });
    return result;
  },
};

// Mutations
const mutations = {
  // Standard mutations from base store class
  ...baseStore.mutations,
  LIST_SET(state, data) {
    state.list[data.key] = data.value;
  },
  PURCH_ITEM_RESET(state) {
    state.currentItemCopy = {};
    state.relatedIssues = [];
  },
  PAST_RECEIVE_ACTIVITY_SET(state, data) {
    state.receiveActivity = [...data];
    const totalReceivedQty = data.reduce(
      (sum, item) => sum + item.receivedQty,
      0
    );
    state.currentItem.pastReceivedQty = totalReceivedQty;
  },
  PAST_RECEIVE_QUANTITY_CLEAR(state) {
    state.receiveActivity = [];
    state.currentItem.pastReceivedQty = 0;
  },
  RELATED_ISSUES_SET(state, data) {
    state.relatedIssues = data;
  },
  SET_SAVED_MAPS(state, newList) {
    state.savedMaps = newList;
  },
  SET_SAVED_MAP_ACTIVE_FIELD(state, data) {
    // If current map is active, all others should be inactive
    const isActive = data.isActive;
    state.savedMaps.forEach((savedMap) => {
      if (savedMap.id === data?.map.id) {
        savedMap.isActive = isActive;
      } else {
        // Turn all others inactive
        savedMap.isActive = false;
      }
    });
    const field = state.savedMaps.find((f) => f.id === data.map.id);
    if (field) {
      field.isActive = data.isActive;
    }
  },
  SET_FILE_UPLOAD_MAP_FIELD(state, data) {
    const field = state.currentUploadMap.find((f) => f.name === data.name);
    if (field) {
      field.mappedLabel = data.mappedLabel;
      field.pctDataFilled =
        data.pctDataFilled || data?.mappedLabel?.pctDataFilled;
      // No update savedMap.uploadMap item to match
    }
  },
  SET_FILE_UPLOAD_MAP_FIELDS(state, data) {
    data.forEach((field) => {
      const currentMap = state.currentUploadMap.find(
        (f) => f.name === field.name
      );
      if (currentMap && field.mappedLabel) {
        //this._vm.$set(currentMap, "mappedLabel", field.mappedLabel);
        currentMap.mappedLabel = field.mappedLabel;
        currentMap.isHovered = false;
        currentMap.pctDataFilled = field.pctDataFilled;
      }
    });
  },
  CLEAR_FILE_UPLOAD_MAP_FIELD(state, data) {
    const field = state.currentUploadMap.find(
      (field) => field.name === data.name
    );
    if (field) {
      field.mappedLabel = null;
      field.pctDataFilled = null;
    }
  },
  CLEAR_ALL_FILE_UPLOAD_MAP_FIELDS(state) {
    state.currentUploadMap.forEach((field) => {
      field.mappedLabel = null;
      field.pctDataFilled = null;
    });
    // Make all field maps inactive
    state.savedMaps.forEach((savedMap) => {
      savedMap.isActive = false;
    });
  },
  SET_FILE_UPLOAD_MAP_HOVERED(state, data) {
    const field = state.currentUploadMap.find((f) => f.name === data.name);
    if (field) {
      field.isHovered = data.isHovered;
    }
  },
  SET_FIELDS(state, data) {
    state.list.fields = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  SORT_UPLOAD_MAPS(state) {
    state.savedMaps.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  },
  ADD_UPLOAD_MAP(state, data) {
    data.isActive = true;
    state.savedMaps.unshift(data);
  },
  MOVE_UPLOAD_TO_TOP(state, id) {
    const index = state.savedMaps.findIndex((item) => item.id === id);
    if (index !== -1) {
      const [item] = state.savedMaps.splice(index, 1);
      state.savedMaps.unshift(item);
    }
  },
  UPDATE_UPLOAD_MAP_RECORD(state, data) {
    data.isActive = true;
    // data.lastUsedOn = new Date();
    const foundIndex = state.savedMaps.findIndex((f) => f.id === data.id);
    if (foundIndex !== -1) {
      state.savedMaps[foundIndex] = data;
      // Re-sort with changed item at top
      const [item] = state.savedMaps.splice(foundIndex, 1);
      state.savedMaps.unshift(item);
    }
  },
  UPDATE_UPLOAD_MAP_TITLE(state, data) {
    const useId = data.id;
    const useName = data.name;
    const foundRec = state.savedMaps.find((f) => f.id === useId);
    if (foundRec) {
      foundRec.name = useName;
    }
    // Re-sort
    const index = state.savedMaps.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      const [item] = state.savedMaps.splice(index, 1);
      state.savedMaps.unshift(item);
    }
  },
  SAVE_UPLOAD_MAP_COMPARISON(state, data) {
    const foundRec = state.savedMaps.find((f) => f.id === data.id);
    if (foundRec) {
      foundRec.uploadMap = data.uploadMap;
    }
  },
  LOAD_UPLOAD_MAP(state, data) {
    // Add isActive flag to track which map is active in UI
    if (!data) return;
    data.forEach((map) => {
      map.isActive = false;
    });
    state.savedMaps = data;
  },
  DELETE_UPLOAD_MAP(state, id) {
    state.savedMaps = state.savedMaps.filter((item) => item.id !== id);
  },
  // UPLOAD_MAP_LAST_USED(state, data) {
  //   const foundMap = state.savedMaps.find((x) => x.id === data.id);
  //   if (foundMap) {
  //     foundMap.lastUsedOn = new Date();
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
